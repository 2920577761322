import axios from 'axios';
// const url = 'https://apiendpoints.edhub.school/public/index.php/api/'
// const corsUrl = 'https://apiendpoints.edhub.school/public/index.php/'
// const url = 'http://localhost:8080/api/'
// const corsUrl = 'http://localhost:8080/'
const url = process.env.REACT_APP_API_URL + 'api/'
// const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Product = {
    // Get List of all products
    getProducts: function () {
        return axios({
            "method": "GET",
            "url": url + 'products',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
        })
    },
    getCheck: function () {
        return axios({
            "method": "GET",
            "url": 'https://reqres.in/api/users?page=2',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
        })
    },
    getCourseLevels: function () {
        return axios({
            "method": "GET",
            "url": url + 'course/levels',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
        })
    },
    getSubjects: function () {
        return axios({
            "method": "GET",
            "url": url + 'course/subjects',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
        })
    },
    addCourseLevel: function (data) {
        console.log(data)
        return axios({
            "method": "POST",
            "url": url + 'course/level/add',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
            "data": { title: data.title, subject_id: data.subject_id }
        })
    },
    updateCourseLevel: function (levels, subject_id) {
        console.log(subject_id, levels)
        return axios({
            "method": "POST",
            "url": url + 'course/level/update',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
            "data": { levels: levels, subject_id: subject_id }
        })
    },
    addSubject: function (name) {
        return axios({
            "method": "POST",
            "url": url + 'course/subject/add',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
            "data": { title: name }
        })
    },
    deleteCourseLevel: function (id) {
        return axios({
            "method": "POST",
            "url": url + 'course/level/delete',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
            "data": { id: id }
        })
    },
    deleteSubject: function (id) {
        return axios({
            "method": "POST",
            "url": url + 'course/subject/delete',
            "headers": {
                // 'Access-Control-Allow-Origin': '*',
                "content-type": "application/octet-stream",
                "content-type": "application/json",
            },
            "data": { id: id }
        })
    },
    // Get Recommended products
    getRecommendedProducts: function (user) {
        return axios({
            "method": "GET",
            "url": url + 'products/recommended/' + user,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },
    // Get Trending products
    getTrendingProducts: function () {
        return axios({
            "method": "GET",
            "url": url + 'products/trending',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },
    // Get Best products this month
    getBestProducts: function () {
        return axios({
            "method": "GET",
            "url": url + 'products/monthly',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    getTags: function () {
        return axios({
            "method": "GET",
            "url": corsUrl + 'tags',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    getDisplayNames: function () {
        return axios({
            "method": "GET",
            "url": corsUrl + 'displayNames',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    getLevels: function () {
        return axios({
            "method": "GET",
            "url": corsUrl + 'levels',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    getCategory: function () {
        return axios({
            "method": "GET",
            "url": corsUrl + 'category',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    submitProductAddForm: function (dataFile) {
        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        }
        return axios({
            "method": "POST",
            "url": corsUrl + 'addProduct',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
                "content-type": "video/mp4",
            },
            "data": dataFile,
        }, config)
    },

    submitProductEditForm: function (dataFile) {
        const config = {
            onUploadProgress: function (progressEvent) {
                console.log('kkk')
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        }
        return axios({
            "method": "POST",
            "url": corsUrl + 'editProduct',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
                "content-type": "video/mp4",
            },
            "data": dataFile
        }, config)
    },

    getProductGrad: function () {
        return axios({
            "method": "GET",
            "url": corsUrl + 'grad',
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Add Review
    addReview: function (review) {
        var data = JSON.stringify(review);
        // console.log(review)
        return axios({
            "method": "POST",
            "url": corsUrl + 'product/addreview',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": data
        })
    },

    // Search Product by title
    searchProduct: function (title, levels, tags) {
        var data = JSON.stringify({
            "levels": levels,
            "tags": tags
        });
        return axios({
            "method": "POST",
            "url": corsUrl + 'search/product/1/' + title,
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": data
        })
    },

    // Add Product to Favourite
    addToFavourite: function (user, course) {
        var data = JSON.stringify({
            "user_id": user,
            "course_id": course,
            "subscriber_id": 0
        });
        console.log(data)
        return axios({
            "method": "POST",
            "url": corsUrl + 'product/add/favourite',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": data
        })
    },

    // Filter Products
    filterProduct: function (levels, tags) {

        if (levels.length === 0 && tags.length === 0) {
            return axios({
                "method": "GET",
                "url": corsUrl + 'shop/0',
                "headers": {
                    "content-type": "application/octet-stream",
                }, "params": {
                    "language_code": "en"
                }
            })
        }
        else {
            var data = JSON.stringify({
                "levels": levels,
                "tags": tags
            });
            return axios({
                "method": "POST",
                "url": corsUrl + 'filter/products',
                "headers": {
                    "content-type": "application/json",
                    "content-type": "text/plain",
                },
                "data": data
            })
        }
    },

    // Get Product by Category
    getProductsByCategory: function (category) {
        return axios({
            "method": "POST",
            "url": corsUrl + 'category/product/' + category,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Get Favourites by User
    getFavourites: function (user) {
        return axios({
            "method": "GET",
            "url": corsUrl + 'favourites/' + user,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Remove Favourite by user and course id
    removeFavourite: function (user, course) {
        return axios({
            "method": "GET",
            "url": corsUrl + 'favourite/remove/' + user + '/' + course,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Get Product by Category
    getProductsByCenter: function (center, type) {
        return axios({
            "method": "POST",
            "url": corsUrl + 'center/product/' + center + '/' + type,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Get User's Products by User Id
    getProductsByUserId: function (user) {
        return axios({
            "method": "GET",
            "url": corsUrl + 'user/products',
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "user_id": user,
                "offset": 0,
                "limit": 48
            }
        })
    },

    // Get Product by Category
    shop: function (offset) {
        return axios({
            "method": "GET",
            "url": corsUrl + 'shop/' + offset,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    // Get Product By ID (course_id)
    getProduct: function (id, status = true) {
        return axios({
            "method": "GET",
            "url": url + 'products/' + id,
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "status": status
            }
        })
    },

    // Get Product By ID and Access Code (course_id)
    getProductAccess: function (id, status = true, ip) {
        return axios({
            "method": "GET",
            "url": corsUrl + 'product/access/' + id,
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "status": status
            }
        })
    },

    // Store IP Address
    storeIPAddress: function (id, ip) {
        var data = JSON.stringify({
            "course_id": id,
            "ip_address": ip
        });
        return axios({
            "method": "POST",
            "url": url + 'products/storeip',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": data
        })
    },

    // Check IP Address
    checkIPAddress: function (id, ip) {
        console.log(id, ip)
        var data = JSON.stringify({
            "course_id": id,
            "ip_address": ip
        });
        return axios({
            "method": "POST",
            "url": url + 'products/checkip',
            "headers": {
                "content-type": "application/octet-stream",
            },
            "data": data
        })
    },

    myIpAddress: async function () {
        return await axios({
            "method": "GET",
            "url": "https://www.cloudflare.com/cdn-cgi/trace",
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },

    getProductsByTeacherId: function (teacher, status = 1, orderBy = null, searchQuery = null, limit, offset) {
        return axios({
            "method": "GET",
            "url": url + 'teacherproductsmarkeplace',
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "teacher_id": teacher,
                'status': status,
                'orderBy': orderBy,
                'searchQuery': searchQuery,
                'limit': limit,
                'offset': offset
            }
        })
    },

    deleteProductByTeacher: function (course_id) {
        return axios({
            "method": "GET",
            "url": url + 'teacherproducts/delete',
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "course_id": course_id,
            }
        })
    },

    changeProductByTeacher: function (course_id, status) {
        return axios({
            "method": "GET",
            "url": url + 'teacherproducts/status/' + status,
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "course_id": course_id,
            }
        })
    },
}

export default Product;