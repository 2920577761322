import axios from 'axios';
import { useJwt } from "react-jwt";
const token = localStorage.getItem('jwt_token');
// const url = 'https://apiendpoints.edhub.school/public/index.php/api/'
// const corsUrl = 'https://apiendpoints.edhub.school/public/index.php/'
// const url = 'http://localhost:8080/api/'
// const corsUrl = 'http://localhost:8080/'
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Auth = {

    // Login User
    loginUser: function (username, password) {
        return axios({
            "method": "POST",
            "url": url + 'user/login',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "username": username,
                "password": password
            },
        })
    },

    // Delete User
    deleteUser: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'user/delete',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            'data':data
        })
    },

    resendVerificationEmail: function (id) {
        return axios({
            "method": "GET",
            "url": url + 'resend/verification_email/' + id,
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            },
        })
    },

    authenticateUser: function (token) {
        const headers = {
            "content-type": "text/plain",
        }

        return axios.post(url + 'user/authenticate', { token: token }, { headers: headers })
    },

    authenticateAdmin: function (token) {
        const headers = {
            "content-type": "text/plain",
        }
        return axios.post(url + 'user/authenticate', { token: token }, { headers: headers })
    },

    verifyEmail: function (token) {
        return axios({
            "method": "GET",
            "url": url + 'verify/email',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "token": token
            },
        })
    },

    getUserById: function (id) {
        return axios({
            "method": "GET",
            "url": url + 'user/get',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "id": id,
            },
        })
    },

    updateUserById: function (user) {
        return axios({
            "method": "POST",
            "url": url + 'user/update',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": user
        })
    },

    socialAuth: function (socialUser) {
        return axios({
            "method": "POST",
            "url": url + 'user/social_auth',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": JSON.stringify(socialUser)
        })
    },

    // Register User
    register: function (user) {
        return axios({
            "method": "POST",
            "url": url + 'user/register',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "username": user.username,
                "password": user.password,
                "confirmpassword": user.confirmpassword,
                "first_name": user.first_name,
                "last_name": user.last_name,
                "display_name": user.first_name + ' ' + user.last_name,
                "email": user.email,
                "subscriber_id": user.subscriber_id
            },
        })
    },

    // Register Teacher
    registerTeacher: function (teacher) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/register',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": teacher
        })
    },

    // Forgot Password
    resetPassword: function (email) {
        console.log(email)
        return axios({
            "method": "POST",
            "url": url + 'user/resetpassword',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": {
                email: email
            }
        })
    },



}

export default Auth;