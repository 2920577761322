import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import '../css/common.css';
import Auth from '../../services/auth';

export default function Forgotpassword(props) {
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState(null)
    const [response, setResponse] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [success, setSuccess] = React.useState(null)
    let formFields = {
        email: '',
    }
    const [formValues, setFormValues] = React.useState(formFields)

    const handleFieldChange = (event) => {
        setFormValues({
            ...formValues,
            email: event.target.value
        })
    }

    const handleHitEnter = (event) => {
        if (event.key == 'Enter')
            handleFormSubmission()
    }

    const handleFormSubmission = () => {
        setError(false)
        setMessage(null)
        setSuccess(null)
        setLoading(true)
        if (formValues.email == '') {
            setError(true)
            setMessage('Email is required.')
        }
        else if (formValues.email.indexOf('@') < 0) {
            setError(true)
            setMessage('Enter a valid email address')
        }
        else {
            Auth.resetPassword(formValues.email).then(res => {
                setLoading(false)
                setSuccess(!res.data.error)
                setResponse(res.data.message)
                if (!res.data.error) {
                    setFormValues({
                        ...formValues,
                        email: ''
                    })
                }
            }, error => {
                setLoading(false)
                console.log(error)
            })
        }

    }

    return (
        <Grid>
            <Card className="round-corner-card">
                <CardContent className="card-content-center d-flex-column">
                    <img className="brand-logo-sm" src="/assets/Logo.png" />
                    <Typography className="text-center heading-primary heading-sm" variant="h6">Forgot Password</Typography>
                    {
                        success == false &&
                        <Typography className="text-center heading-primary heading-xs text-danger" variant="h6">{response}</Typography>
                    }
                    {
                        success == true &&
                        <Typography className="text-center heading-primary heading-xs" variant="h6">{response}</Typography>
                    }
                    <div className="form-wrapper">
                        <TextField fullWidth value={formValues.email} onKeyDown={handleHitEnter} onChange={handleFieldChange} variant="outlined" margin="normal" required id="email" label="Email" name="email" autoFocus />
                        {
                            error &&
                            <span className="form-error-message">{message}</span>
                        }
                        <Button onClick={handleFormSubmission} disabled={loading} variant="contained" color="primary" className="primary-btn" style={{ marginTop: 10 }} fullWidth>
                            {
                                loading ?
                                    'Please wait...'
                                    :
                                    'Reset password'
                            }

                        </Button>
                        <Typography onClick={() => props.handleForgotPassword(false)} style={{ textDecoration: 'none', marginTop: 20, textAlign: 'center', fontSize: 16, color: '#3F51B5', cursor: 'pointer' }} >Sign In</Typography>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    )
}
