import React, { useState, createContext } from 'react';
import AuthService from '../services/auth';
import AdminService from '../services/admin';
import { useJwt } from 'react-jwt';
import ProductService from '../services/products';
import Roles from '../routes/roles';
import { red } from '@material-ui/core/colors';
export const UserContext = createContext();

export const UserProvider = props => {
    const [token, setToken] = useState(localStorage.getItem('jwt_token'));
    const [currentUser, setCurrentUser] = useState({})
    const [usersProducts, setUsersProducts] = useState([])
    const [updateUserProducts, setUpdateUserProducts] = useState()
    const mounted = React.useRef()
    const { decodedToken, isExpired } = useJwt(token);
    const [userRole, setUserRole] = useState(null)
    const [teacherNotification, setTeacherNotification] = useState(false)
    const [accountNotification, setAccountNotification] = useState(false)
    const [test, setTest] = useState('check')
    const [jwtModified, setJwtModified] = useState(false)
    React.useEffect(() => {

        if (decodedToken)
            setUserRole(Roles[decodedToken.auth_type])

        if (!mounted.current) {
            mounted.current = true
            let user = localStorage.getItem('user_id');
            if (user) {
                AuthService.getUserById(user).then(res => {
                    setCurrentUser(res.data)
                    if (res.data.role_id == 4) {

                        AdminService.getTeacherDocumentRequest(res.data.auth_id).then(res => {
                            if (res.data.length > 0) {
                                setTeacherNotification(true)
                            }
                            else {
                                setTeacherNotification(false)
                            }
                        })
                        if (res.data.stripe_connect_id == null) {
                            setAccountNotification(true)
                        }
                        else {
                            setAccountNotification(false)
                        }
                    }

                })
            }
        }
    }, [currentUser, accountNotification])

    React.useEffect(() => {
        ProductService.getProductsByUserId(currentUser.user_id).then((res) => {
            setUsersProducts(res.data.products.map(product => product.course_id))
            // console.log(res.data)
        })
        // console.log(currentUser)
    }, [updateUserProducts, currentUser])

    return (
        <UserContext.Provider value={{ jwt: [token, setToken], updateProducts: [updateUserProducts, setUpdateUserProducts], products: [usersProducts, setUsersProducts], user: [currentUser, setCurrentUser], someTest: [test, setTest], accntNotification: [accountNotification, setAccountNotification], notification: [teacherNotification, setTeacherNotification], role: [userRole, setUserRole], isModified: [jwtModified, setJwtModified], decoded: decodedToken, isExpired: isExpired }}>
            {props.children}
        </UserContext.Provider>
    )
}
