import React from 'react';
import * as Mui from '@material-ui/core';
import '../css/passCode.css';
import ProductService from '../../services/products';
import RICIBs from 'react-individual-character-input-boxes';
import { useLocation } from 'react-router-dom';
import { browserName, browserVersion } from "react-device-detect";


export default function PassCodePopUp(props) {

    let references = { 0: React.useRef(null), 1: React.useRef(null), 2: React.useRef(null), 3: React.useRef(null), 4: React.useRef(null), 5: React.useRef(null) }


    const [passCode, setPassCode] = React.useState({ '0': null, '1': null, '2': null, '3': null, '4': null, '5': null })
    const [verified, setVerified] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(false)
    const [expired, setExpired] = React.useState(false)
    const [deleted, setDeleted] = React.useState(false)
    const [code, setCode] = React.useState(null)
    const [courseName, setCourseName] = React.useState('...')
    const [browserChecked, setBrowserChecked] = React.useState(false)
    const [validBrowser, setValidBrowser] = React.useState(false)
    const [accessDenied, setAccessDenied] = React.useState(false)
    const location = useLocation();
    let courseId = 0;

    function detectBrowser() {
        // if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        //     return 'Opera';
        // } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        //     return 'Chrome';
        // } else if (navigator.userAgent.indexOf("Safari") != -1) {
        //     return 'Safari';
        // } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        //     return 'Firefox';
        // } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
        //     return 'IE';//crap
        // } else {
        //     return 'Unknown';
        // }
        return browserName
    }


    React.useEffect(() => {
        let url = window.location.pathname
        if (location.state) {
            let accessCode = location.state.code
            if (accessCode) {
                setCode(accessCode)
                handleChangeInput(accessCode)
            }
        }
        courseId = url.substring(url.lastIndexOf('/') + 1);
        ProductService.getProduct(courseId).then(res => {
            setCourseName(res.data[0].course_name)
        })
    }, [])

    React.useEffect(() => {
        setValidBrowser(detectBrowser() == 'Chrome' || detectBrowser() == 'Mobile Safari' || detectBrowser() == 'Safari')
        setBrowserChecked(true)
    }, [])

    const handleChangeInput = (value) => {
        // console.log(value)
        if (value.length == 6) {
            let url = window.location.pathname
            courseId = url.substring(url.lastIndexOf('/') + 1);
            setSubmitting(true)
            setVerified(null)
            setTimeout(() => {
                let myIp = '0';
                ProductService.myIpAddress().then(data => {
                    myIp = data.data.trim().split('\n').reduce(function (obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                    }, {});
                    // ProductService.checkIPAddress(courseId, myIp.ip).then(res => {
                    // if (res.data.length == 0) {
                    ProductService.getProduct(courseId).then(res => {
                        console.log(res)
                        var today = new Date();
                        var currentdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                        if (res.data[0].active) {
                            if (new Date(res.data[0].course_end_date) < new Date(currentdate)) {
                                setExpired(true)
                                setSubmitting(false)
                            }
                            else {
                                if (value.toUpperCase() === res.data[0].access_code) {
                                    setSubmitting(false)
                                    setVerified(true)
                                    setTimeout(() => {
                                        props.handleGrant()
                                    }, 1000)
                                }
                                else {
                                    setVerified(false)
                                    setSubmitting(false)
                                }
                            }
                        }
                        else {
                            setDeleted(true)
                            setSubmitting(false)
                        }
                    }, error => {
                        console.log(error)
                        setSubmitting(false)
                    })
                    // }
                    // else {
                    //     setSubmitting(false)
                    //     setAccessDenied(true)
                    // }
                    // })
                })
            }, 1500)
        }
    };

    const handlePasteValue = (e) => {
        const copiedCode = e.clipboardData.getData("text/plain")
        const slicedCode = copiedCode.split("")
        for (let index = 1; index < 6; index++) {
            document.getElementsByName('input' + index)[0].value = slicedCode[index]
            // document.getElementsByName('input' + index)[0].focus()
        }
    }

    return (
        <div className="body-wrapper">
            <Mui.Grid container justifyContent="center" direction="column" alignItems="center" maxWidth="sm">
                <Mui.Grid item style={{ textAlign: 'center' }}>
                    <img style={{ width: '40%', marginBottom: 40 }} color="transparent" src="/assets/Logo.png" />
                </Mui.Grid>
                {
                    browserChecked &&
                    <>
                        {
                            validBrowser ?
                                <Mui.Grid item className="passcode-section" xs={12} sm={10} md={7} lg={6}>
                                    {
                                        verified == null &&
                                        <div className="lock-wrapper">
                                            <img width="50%" src="/assets/lock.png" />
                                        </div>
                                    }
                                    {
                                        verified == true &&
                                        <div className="lock-wrapper-success">
                                            <img width="50%" src="/assets/unlock.png" />
                                        </div>
                                    }
                                    {
                                        verified == false &&
                                        <div className="lock-wrapper-danger">
                                            <img width="50%" src="/assets/lockError.png" />
                                        </div>
                                    }
                                    {
                                        code == null &&
                                        <>
                                            <h2 style={{ color: '#fa9c24', marginBottom: 0 }}>{courseName}</h2>
                                            <h2 style={{ fontSize: 18 }}>Enter Access Code</h2>
                                            <h5 >Enter 6-digit code to access this course</h5>
                                            <div className="input-wrapper" style={{ marginTop: 10 }}>
                                                {/* <form>
                                    <input name="0" disabled={submitting} ref={references[0]} type="text" onKeyUp={handleChangeInput} value={passCode[0]} />
                                    <input name="1" disabled={submitting} ref={references[1]} type="text" onKeyUp={handleChangeInput} value={passCode[1]} />
                                    <input name="2" disabled={submitting} ref={references[2]} type="text" onKeyUp={handleChangeInput} value={passCode[2]} />
                                    <input name="3" disabled={submitting} ref={references[3]} type="text" onKeyUp={handleChangeInput} value={passCode[3]} />
                                    <input name="4" disabled={submitting} ref={references[4]} type="text" onKeyUp={handleChangeInput} value={passCode[4]} />
                                    <input name="5" disabled={submitting} ref={references[5]} type="text" onKeyUp={handleChangeInput} value={passCode[5]} />
                                </form> */}

                                                <RICIBs
                                                    amount={6}
                                                    autoFocus
                                                    handleOutputString={handleChangeInput}
                                                    inputProps={[
                                                        { className: "input-box", onPaste: handlePasteValue },
                                                    ]}
                                                    inputRegExp={/^[a-zA-Z0-9_.-]*$/}
                                                />
                                            </div>
                                        </>
                                    }
                                    {
                                        verified &&
                                        <h4 className="text-success">Access granted</h4>
                                    }
                                    {
                                        verified == false &&
                                        <h4 className="text-danger">Invalid access code!</h4>
                                    }
                                    {
                                        submitting == true &&
                                        <h4 className="text-secondary">Verifying access...</h4>
                                    }
                                    {
                                        expired == true &&
                                        <h4 className="text-danger">This product is expired.</h4>
                                    }
                                    {
                                        accessDenied == true &&
                                        <h4 className="text-danger">Access denied!</h4>
                                    }
                                    {
                                        deleted == true &&
                                        <h4 className="text-danger">Product does not exist or might have been deleted!</h4>
                                    }
                                </Mui.Grid>
                                :
                                <Mui.Grid className="passcode-section">
                                    <h2 style={{ color: '#fa9c24', marginBottom: 10, textAlign: 'center' }}>{courseName}</h2>
                                    <h4 style={{ textAlign: 'center', color: '#d14e4e' }}>Please use Chrome or Safari browser to access the course.</h4>
                                </Mui.Grid>
                        }
                    </>
                }
            </Mui.Grid>
        </div>
    )
}
